import t from '@jetshop/intl';
import { ErrorMessage, Field } from 'formik';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import {
  InputError,
  InputPositive,
  ToggleViewPasswordButton
} from './InputStatus';

import { theme } from '../Theme';

const Input = styled('input')`
  height: 40px;
  background: #fcfcfc;
  border: 1px solid #dedede;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-bottom: 0.25rem;
  padding-right: 40px;
  position: relative;

  &.disabled {
    background: #ffffff;
    color: #808080;
  }

  &:focus {
    outline: none;
  }

}
`;

export const Wrapper = styled('div')`
  margin-bottom: 1rem;

  .label-checkbox {
    span {
      height: 16px;
      width: 16px;
      svg {
        path {
          fill: ${theme.colors.white};
        }
      }
    }
    input:checked + span {
      background: ${theme.colors.gold};
      border-color: ${theme.colors.gold};
    }
    input:focus + span {
      border-color: ${theme.colors.gold};
    }

    &:hover {
      color: ${theme.colors.gold};
      span {
        border-color: ${theme.colors.gold};
      }
    }
  }
`;

export const FocusWrapper = styled('div')`
  position: relative;
  &:after {
    transition: 1s;
    height: 2px;
    width: 35px;
    position: absolute;
    bottom: -12px;
    left: 0;
    transform: translateX(50%);
    background: transparent;
    content: '';
  }
  &:focus-within {
    &:after {
      background: ${theme.colors.gold};
      transform: translateX(0);
    }
  }
`;

export const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: ${theme.colors.red};
`;

export const Label = styled('label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.25rem;
  display: block;
  .req {
    color: #707070;
    margin-left: 0.5em;
  }
  &.disabled {
    color: #808080;
  }
`;

const AboveInputRow = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  disableValidation,
  ...props
}) => {
  const [currentType, setType] = useState(type);
  return (
    <Wrapper className={props.wrapperClassName}>
      <AboveInputRow>
        <Label className={cx(disabled && 'disabled')} htmlFor={name}>
          <span>{label || name}</span>
          {required && <span className="req">{t('(Required)')}</span>}
        </Label>
        {!disableValidation && (
          <ErrorMessage name={name} component={ErrorSpan} />
        )}
      </AboveInputRow>
      <Field
        className={cx(disabled && 'disabled')}
        id={name}
        name={name}
        render={({ field, form: { touched, errors } }) => (
          <FocusWrapper>
            <Input
              {...props}
              {...field}
              id={name}
              disabled={disabled}
              type={currentType}
            />
            {type === 'password' && (
              <ToggleViewPasswordButton
                className={currentType === 'password' ? 'hidden' : 'visible'}
                onClick={() => {
                  currentType === 'password'
                    ? setType('text')
                    : setType('password');
                }}
              />
            )}
            {!disableValidation && (
              <>
                {touched?.field?.name && errors?.field?.name && <InputError />}
                {touched?.field?.name && !errors?.field?.name && (
                  <InputPositive />
                )}
              </>
            )}
          </FocusWrapper>
        )}
      />
    </Wrapper>
  );
};

export default InputWithLabel;
