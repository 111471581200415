import t from '@jetshop/intl';
import { Formik } from 'formik';
import { styled } from 'linaria/react';
import React from 'react';
import { DynamicContentRenderer } from '../../ContentEditor/DynamicContentRenderer';
import Input from '../../Forms/Input';
import {
  CustomerStatusHeaderText,
  LogInPageMaxWidth,
  StyledTrendButton
} from './VoyadoLookup';

export const LookupFieldContainer = styled.section`
  max-width: 400px;
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  dl {
    display: flex;
    flex-flow: row wrap;
  }
  dt {
    flex-basis: 50%;
    padding: 2px 4px;
    text-align: right;
    font-weight: 600;
    ::after {
      content: ':';
    }
  }
  dd {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
  }
`;

const VoyadoDynamicHeaderRendererWrapper = styled('div')`
  margin: auto;
`;

export const VoyadoDynamicHeaderRenderer = ({
  categoryId,
  rendererComponents
}) => {
  return (
    <VoyadoDynamicHeaderRendererWrapper>
      <DynamicContentRenderer
        categoryId={categoryId}
        rendererComponents={rendererComponents}
      />
    </VoyadoDynamicHeaderRendererWrapper>
  );
};

export const ExternalLookupField = ({ lookup, isExternalLookupPending }) => {
  return (
    <>
      <VoyadoDynamicHeaderRenderer categoryId={337} />
      <LogInPageMaxWidth>
        <CustomerStatusHeaderText>
          {t(
            'Enter your { lookupKey } and we will check if you are an existing member or not.',
            { lookupKey: 'mobilnummer' }
          )}
        </CustomerStatusHeaderText>
        <Formik
          initialValues={{ lookupKey: '' }}
          onSubmit={values => lookup(values.lookupKey)}
        >
          {({ handleChange, handleBlur, handleSubmit }) => {
            return (
              <LookupFieldContainer>
                <form onSubmit={handleSubmit}>
                  <Input
                    label={t('mobilePhone')}
                    name="lookupKey"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <StyledTrendButton
                    type="submit"
                    data-testid="commit-lookup"
                    loadingText={t('Hold on a moment...')}
                  >
                    {t('Login')}
                  </StyledTrendButton>
                </form>
              </LookupFieldContainer>
            );
          }}
        </Formik>
      </LogInPageMaxWidth>
    </>
  );
};
