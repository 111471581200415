import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { useVoyadoLookup } from '@jetshop/flight-voyado';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Redirect } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';

import { GlobalError } from '../../Forms/GlobalError';
import Input from '../../Forms/Input';
import MaxWidth from '../../Layout/MaxWidth';
import TrendButton from '../../ui/Button';
import { activeSegment, smallSection } from '../UI/Form';
import { theme } from '../../Theme';

import {
  ExternalLookupField,
  LookupFieldContainer,
  VoyadoDynamicHeaderRenderer
} from './VoyadoLookupField';
import { Formik } from 'formik';

export const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  min-width: 155px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: black;
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.max-width {
    align-items: unset;
    width: 50%;
    ${theme.below.lg} {
      width: 80%;
    }
  }
  ${theme.below.lg} {
    padding: 0 1rem;
  }
`;

const LoginPageHeader = styled('header')`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const CustomerStatusHeaderText = styled('h4')`
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 400;
  max-width: 400px;
`;

const country = {
  NO: t('Norge'),
  SE: t('Sverige')
};

function VoyadoLookup() {
  const { ...voyado } = useVoyadoLookup({
    lookupKey: 'mobilePhone'
  });
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  const { logIn, loggedIn } = useAuth();

  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  if (voyado.isAdditionalDataRequired) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: {
              ...voyado.customer
            },
            status: {
              isAdditionalDataRequired: voyado.isAdditionalDataRequired
            }
          }
        }}
      />
    );
  }

  if (voyado.isPersonLookupIdle || voyado.isPersonLookupPending) {
    return (
      <>
        <VoyadoDynamicHeaderRenderer categoryId={341} />
        <LogInPageMaxWidth className="max-width">
          <LookupFieldContainer>
            <CustomerStatusHeaderText>
              {t('Fyll i ditt personnummer')}
            </CustomerStatusHeaderText>
            <Formik
              initialValues={{ lookupKey: '' }}
              onSubmit={values => {
                voyado.personLookup(values.lookupKey);
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    label="PID"
                    name="lookupKey"
                    id="lookupKey"
                  />
                  <StyledTrendButton
                    type="submit"
                    disabled={voyado.isPersonLookupPending}
                    loading={voyado.isPersonLookupPending}
                    loadingText={t('Hold on a moment...')}
                  >
                    {t('Hämta mina uppgifter')}
                  </StyledTrendButton>
                </form>
              )}
            </Formik>
          </LookupFieldContainer>
        </LogInPageMaxWidth>
      </>
    );
  }

  if (voyado.isNonExistingCustomer || voyado.isPersonLookupFailed) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isNonExistingCustomer: voyado.isNonExistingCustomer
            }
          }
        }}
      />
    );
  }

  if (voyado.isCountryNotValid) {
    return (
      <>
        <VoyadoDynamicHeaderRenderer categoryId={342} />
        <LogInPageMaxWidth>
          <CustomerStatusHeaderText data-testid="country-not-valid-status">
            {t.rich(
              "You're trying to activate an account with country { countryCode } that doesn't exist in { market }. Try selecting a country that matches { countryCode } from our country selector.",
              {
                countryCode: country[voyado?.customer?.countryCode],
                market: selectedChannel.url
              }
            )}
          </CustomerStatusHeaderText>
        </LogInPageMaxWidth>
      </>
    );
  }

  if (voyado.isPersonLookupSuccess) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isPersonLookupSuccess: voyado.isPersonLookupSuccess
            }
          }
        }}
      />
    );
  }

  if (voyado.isInActivation) {
    const {
      isActivationRequired,
      isActivationPending,
      isActivationSuccess
    } = voyado;
    return (
      <>
        <VoyadoDynamicHeaderRenderer categoryId={338} />
        <LogInPageMaxWidth style={{ padding: '2rem' }}>
          <CustomerStatusHeaderText
            data-testid={isActivationSuccess && 'successfully-activated'}
          >
            {isActivationRequired &&
              t(
                'You exist as an member, click to activate your account. We will send you an email with a link on how to set your password.'
              )}
            {isActivationSuccess &&
              t('We have successfully activated your account.')}
            {isActivationPending && t('Activating account')}
          </CustomerStatusHeaderText>
          <StyledTrendButton
            data-testid="commit-activation"
            disabled={isActivationPending}
            loading={isActivationPending}
            onClick={() => {
              if (isActivationRequired) {
                voyado.activate();
              } else {
                logIn(voyado.customer.token);
              }
            }}
          >
            {isActivationRequired && t('Activate')}
            {isActivationPending && t('Activating')}
            {isActivationSuccess && t('Login')}
          </StyledTrendButton>
        </LogInPageMaxWidth>
      </>
    );
  }

  if (voyado.isPreExistingCustomer) {
    return (
      <>
        <VoyadoDynamicHeaderRenderer categoryId={339} />
        <LogInPageMaxWidth>
          <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
          <LogInFormProvider
            redirect="my-pages"
            // initialEmail={voyado?.customer?.emailAddress?.masked}
          >
            {({ globalError, isSubmitting, isValid }) => (
              <section className={cx(smallSection, activeSegment)}>
                <LoginPageHeader>
                  <CustomerStatusHeaderText>
                    {t(
                      'We found your account, enter your password to continue.'
                    )}
                  </CustomerStatusHeaderText>
                </LoginPageHeader>

                <Input type="email" name="email" label={t('E-mail address')} />
                <Input type="password" name="password" label={t('Password')} />

                {globalError && (
                  <GlobalError style={{ marginBottom: '2em' }}>
                    {globalError}
                  </GlobalError>
                )}

                <section className={actionWrapper}>
                  <StyledTrendButton
                    type="submit"
                    data-testid="login-button"
                    disabled={!isValid}
                    loading={isSubmitting}
                    loadingText={t('Hold on a moment...')}
                  >
                    {t('Log in')}
                  </StyledTrendButton>
                  <TextLink to={routes.forgotPassword.path}>
                    {t('Forgot password?')}
                  </TextLink>
                </section>
              </section>
            )}
          </LogInFormProvider>
        </LogInPageMaxWidth>
      </>
    );
  }

  return (
    <>
      <ExternalLookupField {...voyado} />

      {(Object.values(voyado.error).some(Boolean) ||
        voyado.isCountryNotValid) && (
        <p>{t('Something went wrong. Please try again')}</p>
      )}
    </>
  );
}

export default VoyadoLookup;
